import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		nickname: '',
		pic: '',
		iflogin:1,
		isShowCode:false,
		notice:0,//未读消息
		istips:0,
	},
	getters: {

	},
	mutations: {
		setNicknameData(state, data) {
			state.nickname = data
		},
		setPicData(state, data) {
			state.pic = data
		},
		setLoginData(state, data) {
			state.iflogin = data
		},
		setShowCode(state, data) {
			state.isShowCode = data
		},
		setNoticeData(state, data) {
			state.notice = data
		},
		setIstipsData(state, data) {
			state.istips = data
		},
		
	},
	actions: {
	},
	modules: {

	}
})