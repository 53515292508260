// 配置环境信息 @/config/config.js
// const development = {
// 	baseUrl: ""
// }
// const production = {
// 	baseUrl: ""
// }
// const prod2 = {
// 	baseUrl: ""
// }
let env	=process.env;
// console.log(process.env);
// // 不使用process.env.NODE_ENV是NODE_ENV只用来区分开发还是生产
// if (process.env.VUE_APP_ENV == 'development') {
// 	env = process.env
// } else if (process.env.VUE_APP_ENV == 'production') {
// 	env = process.env
// } 
export default env;