import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

//路由懒加载
const HomeView = () => import('../views/HomeView.vue')
const GuangView = () => import('../views/GuangView.vue')
const EditView = () => import('../views/EditView.vue')
const UserView = () => import('../views/UserView.vue')
const PubuView = () => import('../views/PubuView.vue')
const ShareView = () => import('../views/ShareView.vue')
const PromView = () => import('../views/PromView.vue')
const RegisterView = () => import('../views/RegisterView.vue')
const ForgetView = () => import('../views/ForgetView.vue')
const MessageView = () => import('../views/MessageView.vue')

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		redirect: '/home',
	},
	{
		path: '/home',
		name: 'home',
		component: HomeView
	},
	{
		path: '/guang',
		name: 'guang',
		component: GuangView
	},
	{
		path: '/edit',
		name: 'edit',
		component: EditView
	},
	{
		path: '/user',
		name: 'user',
		component: UserView
	},
	{
		path: '/share',
		name: 'share',
		component: ShareView
	},{
		path: '/pubu',
		name: 'pubu',
		component: PubuView
	},{
		path: '/prom',
		name: 'prom',
		component: PromView
	},{ //注册会员
		path: '/regi',
		name: 'regi',
		component: RegisterView
	},{ //忘记密码
		path: '/forget',
		name: 'forget',
		component: ForgetView
	},{ 
		path: '/message',
		name: 'message',
		component: MessageView
	},
]

const router = new VueRouter({
	routes,
	mode:'history',
})
router.beforeEach( function( to , from , next) {
	setTimeout(() => {
		store.commit("setShowCode", false); //点击关闭二维码弹框
	},100)
	next()
})

export default router