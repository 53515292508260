export function LoginTimerOut() {
	this.$message({
		message: '请登录',
		type: 'warning',
		//duration:0,
	});
	localStorage.clear()
	this.$store.commit("setNicknameData", '');
	this.$store.commit("setPicData", '');
	this.$store.commit("setLoginData", 0);
	this.$store.commit("setShowCode", true);

	//重置登录二维码
	this.ResetLoginCode()
}

export function ResetLoginCode() { //重置登录二维码
	this.appid = this.env.VUE_APP_WX_APPID
	this.scope = 'snsapi_login'
	this.redirect_uri = this.env.VUE_APP_WX_LOGIN_REDIRECT_URL + this.$route.path;
	this.href = 'https://aiarthub.bedigital.cn/static/css/wxlogin.css?2'
}

import wx from "weixin-js-sdk" //微信分享
export function ShareConfig(eid) {
	return {
		url: this.env.VUE_APP_WX_LOGIN_REDIRECT_URL + '/share?eid=' + eid, // 网址，默认使用 window.location.href
		source: "", // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
		title: "", // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
		description: "", // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
		image: "", // 图片, 默认取网页中第一个img标签
		sites: [
			"qzone",
			"qq",
			"weibo",
			"wechat",
			"douban",
			"tencent",
			"linkedin",
			"google",
			"facebook",
			"twitter",
		], // 启用的站点
		disabled: ["google", "facebook", "twitter", "linkedin", "qzone", "qq", "douban", "tencent", ], // 禁用的站点
		wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
		wechatQrcodeHelper: "<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>",
	}
}

export function getShareInfo(title, desc, link, imgUrl) {
	var url = encodeURIComponent(window.location.href.split("#")[0]);
	this.network.AxiosShareWx(url).then(res => {
		let data = res.data;
		wx.config({
			debug: true,
			appId: data.appId,
			timestamp: data.timestamp,
			nonceStr: data.nonceStr,
			signature: data.signature,
			jsApiList: [
				"onMenuShareTimeline",
				"onMenuShareAppMessage",
				"onMenuShareQQ",
				"onMenuShareWeibo",
				"onMenuShareQZone",
			],
		});
	}).catch(() => {
		this.$message({
			message: '服务器错误，请稍后再试',
			type: 'warning',
		});
	});
	wx.ready(() => {
		/* var shareData = {
			title: title ? title : 'AiArtHub.art',
			desc: desc ? desc : '分享AIArt 共享AIPrompts',
			link: link,
			imgUrl: imgUrl ? imgUrl : 'https://www.aiarthub.art/favicon.png',
			success: () => {},
		}; */
		var shareData = {
			title: 'AiArtHub.art',
			desc: '分享AIArt 共享AIPrompts',
			link: 'https://www.aiarthub.art',
			imgUrl: 'https://www.aiarthub.art/favicon.png',
			success: () => {},
		};
		wx.onMenuShareTimeline(shareData);
		wx.onMenuShareAppMessage(shareData);
		wx.onMenuShareQQ(shareData);
		wx.onMenuShareWeibo(shareData);
		wx.onMenuShareQZone(shareData);
	});
}