import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import 'remixicon/fonts/remixicon.css' //icon字体图标

import axios from 'axios'; //网络请求
Vue.prototype.$axios =axios;

import network from '@/network/index.js' //封装的网络请求方法
Vue.prototype.network = network

import env from '@/config/config.js' //环境配置
Vue.prototype.env = env

import waterfall from 'vue-waterfall2' //瀑布流
Vue.use(waterfall)

import VueClipboard from 'vue-clipboard2' //复制内容
Vue.use(VueClipboard)

import Share from 'vue-social-share' //分享
Vue.use(Share)

/* import wx from "weixin-js-sdk" //微信分享
Vue.use(wx) */

import {LoginTimerOut, ResetLoginCode, ShareConfig, getShareInfo} from '../src/util' //公用方法
Vue.prototype.LoginTimerOut = LoginTimerOut
Vue.prototype.ResetLoginCode = ResetLoginCode
Vue.prototype.ShareConfig = ShareConfig
Vue.prototype.getShareInfo = getShareInfo

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
