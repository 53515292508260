<template>
	<div class="header" :class="[value? 'night' : 'day']">
		<div class="logo">
			<div class="logo_pc">
				<img :src="[value? logoNight : logoDay]" alt="" @click="GoLinkHome">
				<p>分享AIArt 共享AIPrompts</p>
			</div>
			<div class="logo_phone">
				<img :src="[value? logo_phone_Night : logo_phone_Day]" alt="" @click="GoLinkHome">
			</div>
		</div>
		<div class="link">
			<router-link to="/home">提示器</router-link>
			<router-link to="/guang">广场</router-link>
		</div>
		<div class="login">
			<div class="upload">
				<a @click="ClickGoEdit(0)" href="javascript:;" ref="prompts" :class="ClassProm">
					<i class="ri-draft-line"></i><span>上传Prompt</span>
				</a>
				<a @click="ClickGoEdit(1)" href="javascript:;" ref="upload" :class="ClassEdit">
					<i class="ri-image-add-line"></i><span>上传Art</span>
				</a>
				<a href="javascript:;" class="message" :class="ClassMessage"
					@mouseover="mouseOver('messa')" @mouseleave="mouseLeave('messa')">
					<p @click="ClickGoMessage">
						<i class="ri-message-2-line"></i><span>消息</span><b class="tips" v-if="$store.state.istips == 1 || $store.state.notice > 0"></b>
					</p>
					<div class="loginTan messageTan" v-if="isshowMessaTan">
						<div v-for="(item,index) in dataList" :key="index" @click="ClickGoMessage" v-if="islist">
							<span>{{typeDate(item.type,1)}}</span><b>{{item.nickname}}</b>{{typeDate(item.type,2)}}
						</div>
						<div class="kong" v-if="!islist">暂无新消息~</div>
						<div @click="ClickGoMessage" v-if="islist">查看全部</div>
					</div>
				</a>
			</div>
			<!-- 模式切换 -->
			<div v-show="false"><el-switch v-model="value" active-color="#05050F" inactive-color="#EEF0FA"></el-switch>
			</div>
			<!-- 我的 -->
			<div class="user" v-if="openid && $store.state.iflogin == 1" @mouseover="mouseOver('login')"
				@mouseleave="mouseLeave('login')">
				<p><img :src="pic?pic:Normalpic" alt=""></p>
				<span>{{nickname}}</span>
				<div class="loginTan" v-if="isshowLoginTan">
					<div @click="GoUser"><i class="ri-user-line icon"></i>个人主页<i class="ri-arrow-right-s-line"></i>
					</div>
					<div @click.stop="getAxiosOutLogin"><i class="ri-share-circle-line icon"></i>退出登录</div>
				</div>
			</div>
			<div class="share" v-else ref="share">
				<div class="shareBtn" @click="ClickLogin">
					<i class="ri-bear-smile-line"></i><span>登录</span>
				</div>
				<div class="qrcode" v-show="$store.state.isShowCode">
					<wxlogin :appid="appid" :scope="scope" :redirect_uri="redirect_uri" :href="href"></wxlogin>
				</div>
				<!-- <div class="ceng" v-show="$store.state.isShowCode"></div>
				<div class="qrcode" v-show="$store.state.isShowCode">
					<wxlogin :appid="appid" :scope="scope" :redirect_uri="redirect_uri" :href="href"></wxlogin>
					<div class="form">
						<h3>密码登录</h3>
						<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"
							class="demo-ruleForm" :hide-required-asterisk="false" :show-message="false" :status-icon="false">
							<el-form-item prop="email">
								<el-input type="email" v-model="ruleForm.email" @blur="ruleForm.email=$event.target.value.trim()" 
									placeholder='邮箱'></el-input>
							</el-form-item>
							<el-form-item prop="password">
								<el-input type="password" v-model="ruleForm.password" @blur="ruleForm.password=$event.target.value.trim()" placeholder='密码'
									show-password autocomplete="off"></el-input>
							</el-form-item>
							<el-button :disabled='!issubmit' type="primary" @click="submitForm('ruleForm')" class="submitBtn"
								:class="[!issubmit?'on':'']" v-if="!this.$route.query.eid">
								{{issubmit?'立即登录':'登录中'}}<i :class="[issubmit?'':'el-icon-loading']"></i>
							</el-button>
						</el-form>
						<p>
							<a href="javascript:;" @click="GoLinkForget">忘记密码</a>
							<b>|</b>
							<a href="javascript:;" @click="GoLinkRegi">注册</a>
						</p>
					</div>
					<p class="close" @click="close"><i class="ri-close-line"></i></p>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	//import wxlogin from 'vue-wxlogin';
	import wxlogin from '@/components/wxLogin.vue'
	export default {
		name: 'CompHeader',
		components: {
			wxlogin
		},
		data() {
			return {
				code: '',
				logoNight: require('../assets/img/logo_night.png'),
				logoDay: require('../assets/img/logo_day.png'),
				logo_phone_Night: require('../assets/img/logo_phone_night.png'),
				logo_phone_Day: require('../assets/img/logo_phone_day.png'),
				value: true,
				isShowForm: false,
				isshowLoginTan: false, //是否显示个人中心弹框
				isshowMessaTan: false, //是否显示消息中心弹框
				timer: null,
				appid: "",
				scope: 'snsapi_login',
				redirect_uri: '',
				href: 'https://aiarthub.bedigital.cn/static/css/wxlogin.css?0',
				Normalpic: require('../assets/img/home/morenImg.jpg'),
				pic: localStorage.getItem('pic'),
				nickname: localStorage.getItem('nickname'),
				openid: localStorage.getItem('openid'),
				isProm: false,
				isEdit: false,
				isMessage: false,
				istips:0, //是否有新消息
				ismouseover:true,//是否移入消息按钮
				dataList:[],//消息列表
				islist:true,
				/* issubmit:true,
				ruleForm: {
					email: '',
					password: '',
				},
				rules: {
					email: [
						{ required: true, message: '请输入邮箱地址', trigger: 'blur' },
						{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' },
					]
				},
				isclose : localStorage.getItem('isclose'), */
			}
		},
		created() {
			this.appid = this.env.VUE_APP_WX_APPID;
			
			if(this.openid){
				this.localSocket()
				let notice = localStorage.getItem('notice')
				this.$store.commit("setNoticeData",notice) //未读消息
			}
		},
		mounted() {

		},
		methods: {
			//登录
			ClickLogin() {
				this.openid = localStorage.getItem('openid')
				this.nickname = ''
				this.pic = ''
				if (this.$store.state.isShowCode) {
					this.$store.commit("setShowCode", false);
				} else {
					this.$store.commit("setShowCode", true);
				}
				this.ResetLoginCode() //重置登录二维码
			},

			//编辑按钮
			ClickGoEdit(idx) {
				this.openid = localStorage.getItem('openid')
				if (!this.openid) {
					this.LoginTimerOut() //登陆超时的处理函数
					//this.ResetLoginCode() //重置登录二维码
					return false
				}
				let path = ''
				path = idx == 0 ? 'prom' : 'edit'
				this.$router.push({
					path: path,
				}).catch(err => {
					return err
				})
			},
			
			//去消息页
			ClickGoMessage() {
				this.openid = localStorage.getItem('openid')
				if (!this.openid) {
					this.LoginTimerOut() //登陆超时的处理函数
					//this.ResetLoginCode() //重置登录二维码
					return false
				}
				if (this.$route.path != '/message'){
					this.$router.push({
						path: '/message',
					})
				}else{
					location.reload() //刷新页面
				}
			},

			//点击Logo去首页
			GoLinkHome() {
				window.location.href = this.env.VUE_APP_WX_LOGIN_REDIRECT_URL
			},

			//去个人中页
			GoUser() {
				this.isshowLoginTan = false
				if (this.$route.path != '/user') {
					this.$router.push({
						path: '/user',
					})
				}
			},

			//鼠标移上
			mouseOver(type) {
				clearTimeout(this.timer);
				this.openid = localStorage.getItem('openid')
				if (type == 'login') {
					this.isshowMessaTan = false
					this.isshowLoginTan = true
				} else if (type == 'messa' && this.openid) {
					this.isshowLoginTan = false
					this.isshowMessaTan = true
					
					//this.dataList = []
					//有新消息了才更新
					if(this.$store.state.istips == 1 || this.$store.state.notice > 0){
						if(this.ismouseover){
							this.ismouseover = false
							this.islist = true
							this.getAxiosMailList()
						}
					}else{
						this.islist = false
					}
				}
			},
			//鼠标移出
			mouseLeave(type) {
				this.timer = setTimeout(() => {
					if (type == 'login') {
						this.isshowLoginTan = false
					} else if (type == 'messa') {
						this.isshowMessaTan = false
						this.ismouseover = true
					}
				}, 350);
			},
			
			//我的消息列表
			getAxiosMailList() {
				this.network.AxiosMailList(3).then(res => {
					if (res.data.code == 1) { //成功的状态
						this.dataList = res.data.data.list
					} else if (res.data.code < 0) { //请登录的状态
						this.LoginTimerOut() //登陆超时的处理函数
					} else if (res.data.code == 0) {
						this.$message({
							message: res.data.msg,
							type: 'warning',
						});
					} else if (res.data.code == 500) {
						this.$message({
							message: '网络错误，请稍后再试',
							type: 'warning',
						});
					}
				}).catch(() => {
					this.$message({
						message: '服务器错误，请稍后再试',
						type: 'warning',
					});
				});
			},
			
			//退出登录
			getAxiosOutLogin() {
				this.network.AxiosOutLogin().then(res => {
					if (res.data.code == 1) { //成功的状态
						this.$message({
							message: '已退出登录',
							type: 'success',
							iconClass: 'ri-checkbox-circle-line',
							//duration:0,
						});
						localStorage.clear()
						this.$store.commit("setNicknameData", '');
						this.$store.commit("setPicData", '');
						this.$store.commit("setLoginData", 0);
						if (this.$route.path == '/user') {
							this.$router.push({
								path: '/guang',
							}).catch(err => {
								return err
							})
						}
					} else if (res.data.code < 0) { //请登录的状态
						this.LoginTimerOut() //登陆超时的处理函数
					} else if (res.data.code == 0) {
						this.$message({
							message: res.data.msg,
							type: 'warning',
						});
					} else if (res.data.code == 500) {
						this.$message({
							message: '网络错误，请稍后再试',
							type: 'warning',
						});
					}
				}).catch(() => {
					this.$message({
						message: '服务器错误，请稍后再试',
						type: 'warning',
					});
				});
			},

			//关闭
			close() {
				this.$store.commit("setShowCode", false);
				this.$refs.ruleForm.resetFields() //验证重置 
			},
			
			//即时通讯
			localSocket() {
				let ws = new WebSocket('wss://'+ this.env.VUE_APP_WEBSOCKURL +'/wss/?openid='+this.openid);
				// 服务端主动推送消息时会触发这里的onmessage
				ws.onmessage = (e) => {
					// json数据转换成js对象
					//var msg = e.data;
					var msg = JSON.parse(e.data);
					//this.istips = msg.num
					if(msg.num == 1){
						this.$store.commit("setIstipsData", msg.num)
						localStorage.setItem('notice', msg.num) //未读消息
					}
				};
			},
			
			//邮箱登录
			/* submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(this.issubmit){
							this.issubmit = false
							this.network.AxiosMailLogin(this.ruleForm.email, this.ruleForm.password).then(res => {
								if (res.data.code == 1) { //成功的状态
									this.$message({
										message: '登录成功',
										type: 'success',
										iconClass: 'ri-checkbox-circle-line',
										//duration:0,
									});
									
									this.nickname = res.data.data.nickname
									this.pic = res.data.data.pic
									this.openid = res.data.data.openid
									localStorage.setItem('nickname', this.nickname)
									localStorage.setItem('pic', this.pic)
									
									this.$store.commit("setNicknameData", this.nickname);
									this.$store.commit("setPicData", this.pic);
									this.$store.commit("setLoginData", 1);
									
									localStorage.setItem('token_refresh', res.data.data.token.refresh_token)
									localStorage.setItem('token_access', res.data.data.token.access_token)
									localStorage.setItem('openid', res.data.data.openid)
									localStorage.setItem('fans', res.data.data.fans)
									this.close()
									this.$refs.ruleForm.resetFields()//验证重置 
									if (this.$route.path == '/forget' || this.$route.path == '/regi') {
										this.$router.push({
											path: '/home',
										}).catch(err => {
											return err
										})
									}
									this.isshowLoginTan = false
								} else if (res.data.code == 0) {
									this.$message({
										message: '账号/密码错误，请重试',
										type: 'warning',
									});
								} else if (res.data.code == 500) {
									this.$message({
										message: '网络错误，请稍后再试',
										type: 'warning',
									});
								}
								this.issubmit = true
							}).catch(() => {
								this.$message({
									message: '服务器错误，请稍后再试',
									type: 'warning',
								});
								this.issubmit = true
							});
						}
					} else {
						this.$message({
							message: '请填写正确的账号/密码',
							type: 'warning',
						});
						return false;
					}
				});
			}, */
			
			//修改信息
			/* GoRevise() {
				this.$router.push({
					path: '/revise',
				})
				this.isshowLoginTan = false
			}, */
			
			//忘记密码页
			/* GoLinkForget(){
				this.$store.commit("setShowCode", false);
				this.$router.push({
					path: '/forget',
				})
			}, */
			//注册
			/* GoLinkRegi(){
				this.$store.commit("setShowCode", false);
				this.$router.push({
					path: '/regi',
				})
			}, */
			
			/* CloseCode() {
				this.$store.commit("setShowCode", false);
			}, */
			
			weixinClick() { // 点击切换微信扫码登录这一项，并向微信扫码登录
				/* this.isShowForm = false;
				// 微信登录第一步：申请微信登录二维码
				let _this = this;
				new WxLogin({
					id: "weixin",
					appid: "wx93d480f1d9903673", // 这个appid要填死
					scope: "snsapi_login",
					// 扫码成功后重定向的接口
					redirect_uri: "http://aiarthub-api.bedigital.cn/login/callback&response_type=code&scope=snsapi_login",
					// state填写编码后的url
					state: encodeURIComponent(window.btoa("http://127.0.0.1:8080" + _this.$route.path)),
					// 调用样式文件
					href: "",
				}); */
			},
		},
		computed: {
			path2() {
				return this.$route.path;
			},
			ClassProm() {
				return {
					'router-link-active': this.isProm
				}
			},
			ClassEdit() {
				return {
					'router-link-active': this.isEdit
				}
			},
			ClassMessage() {
				return {
					'router-link-active': this.isMessage
				}
			},
			//判断显示内容
			//type  1关注 2作品收藏 3作品点赞 4作品评论 5作品回复评论 6评论艾特 7作品评论点赞
			typeDate () {
				return (type,num) => {
					let html = '', html_2 = '' 
					switch (type){
						case 1:
							html = '【关注】'
							html_2 = '关注了你'
							break;
						case 2:
							html = '【收藏】'
							html_2 = '收藏了你的作品'
							break;
						case 3:
							html = '【点赞】'
							html_2 = '点赞了你的作品'
							break;
						case 4:
							html = '【评论】'
							html_2 = '评论了你的作品'
							break;
						case 5:
							html = '【回复】'
							html_2 = '回复了你的评论'
							break;
						case 6:
							html = '【回复】'
							html_2 = '回复了你的评论'
							break;
						case 7:
							html = '【点赞】'
							html_2 = '点赞了你的评论'
							break;
					}
					if(num == 1){
						return html
					}else if(num == 2){
						return html_2
					}
					
				}
			},
		},
		watch: {
			path2: {
				immediate: true,
				deep: true,
				handler(to) {
					if (to) {
						/* console.log('-----：'+this.$route.path)
						console.log(this.$route.query.code) */
						//this.redirect_uri = 'https://aiarthub.bedigital.cn' + this.$route.path
						this.openid = localStorage.getItem('openid')
						this.redirect_uri = this.env.VUE_APP_WX_LOGIN_REDIRECT_URL + this.$route.path;
						//console.log(this.openid)
						if (!this.openid && this.$route.query.code) {
							this.network.AxiosLogin(this.$route.query.code).then(res => {
								if (res.data.code == 1) {
									this.nickname = res.data.data.nickname
									this.pic = res.data.data.pic
									this.openid = res.data.data.openid
									localStorage.setItem('nickname', this.nickname)
									localStorage.setItem('pic', this.pic)
									
									localStorage.setItem('notice', res.data.data.notice) //未读消息
									this.$store.commit("setNoticeData", res.data.data.notice) //未读消息
									
									this.$store.commit("setNicknameData", this.nickname);
									this.$store.commit("setPicData", this.pic);
									this.$store.commit("setLoginData", 1);

									localStorage.setItem('token_refresh', res.data.data.token.refresh_token)
									localStorage.setItem('token_access', res.data.data.token.access_token)
									localStorage.setItem('openid', res.data.data.openid)
									localStorage.setItem('fans', res.data.data.fans)

									this.localSocket()

									if (this.$route.path == '/user' || this.$route.path == '/home' || this.$route.path == '/message') {
										location.reload() //刷新页面
									}
									this.$message({
										message: '登录成功',
										type: 'success',
										iconClass: 'ri-checkbox-circle-line',
										//duration:0,
									});
								} else if (res.data.code == 500) {
									this.$message({
										message: '网络错误，请稍后再试',
										type: 'warning',
									});
								}
							}).catch(() => {
								this.$message({
									message: '服务器错误，请稍后再试',
									type: 'error',
								});
							});
						}

						if (this.$route.path == '/prom') {
							this.isProm = true
							this.isEdit = false
							this.isMessage = false
						} else if (this.$route.path == '/edit') {
							this.isEdit = true
							this.isProm = false
							this.isMessage = false
						} else if (this.$route.path == '/message') {
							this.isEdit = false
							this.isProm = false
							this.isMessage = true
						} else {
							this.isProm = false
							this.isEdit = false
							this.isMessage = false
						}
					}
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.header {
		width: 100%;
		height: 60px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
		padding: 0 44px 0 44px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		transition: background-color 0.3s;
		-webkit-transition: background-color 0.3s;

		&.day {
			background-color: #fff;
		}

		&.night {
			background-color: #181F33;
		}

		.logo {
			.logo_pc {
				display: flex;
				justify-content: start;
				align-items: flex-end;
			}

			.logo_phone {
				display: none;
			}

			img {
				width: auto;
				height: 22px;
				transition: background-color 0.3s;
				-webkit-transition: background-color 0.3s;
				margin-right: 15px;
				cursor: pointer;
			}

			p {
				font-size: 12px;
				line-height: 14px;
				color: #FEFEFF;
			}
		}

		.link {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 50%;
			left: calc(50% - 10px);
			margin: 0;
			transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);

			a {
				color: #AAAAAA;
				font-size: 16px;
				transition: all 0.3s;
				-webkit-transition: all 0.3s;
				margin: 0 35px;
			}
		}

		&.day .link a.router-link-active {
			color: #383A70;
			font-weight: bold;
		}

		&.night .link a.router-link-active {
			color: #fff;
			font-weight: bold;
		}

		&.day .upload a.router-link-active {
			color: #383A70;
			font-weight: bold;
		}

		&.night .upload a.router-link-active {
			color: #fff;
			font-weight: bold;
		}

		.login {
			margin-left: auto;
			margin-right: 0;
			display: flex;
			justify-content: end;
			align-items: center;

			&>div {
				margin-left: 0;
				font-size: 16px;
				display: flex;
				justify-content: start;
				align-items: center;
				line-height: 21px;
				position: relative;
			}

			.share {
				.shareBtn {
					cursor: pointer;
					transition: color .2s;
					-webkit-transition: color .2s;
					line-height: 21px;

					i {
						font-size: 16px;
						margin-right: 5px;
					}
				}

				.shareBtn:hover {
					color: #6A6A9F;
				}

				.qrcode {
					width: 160px;
					margin-left: 0;
					position: absolute;
					top: 50px;
					right: 0;
					display: block;
					padding-top: 15px;
					padding-bottom: 0;
					border: 0px solid red;
					background-color: #121725;

					&>div {
						margin-left: 0;
					}
				}

			}

			.upload {
				a {
					color: #AAAAAA;
					font-size: 16px;
					transition: all 0.3s;
					-webkit-transition: all 0.3s;
					margin-right: 47px;
					position: relative;

					&.message {
						.tips {
							width: 6px;
							height: 6px;
							display: block;
							position: absolute;
							top: 0;
							right: -8px;
							background-color: #FF2B37;
							border-radius: 50%;
						}

						.messageTan {
							/* padding-top: 0;
							padding-bottom: 0; */
							top: 50px;
							div {
								/* display: block;
								justify-content: start;
								align-items: center; */
								padding-left: 0;
								padding-right: 0;
								color: #fff;
								font-weight: normal;
								transition: all 0.2s;
								-webkit-transition: all 0.2s;
								font-size: 14px;

								b{
									display: inline-block;font-weight: normal;
									max-width: 85px;
									overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
								}

								&:hover {
									background-color: #181F33;
									color: #AAAAAA;
								}
								&.kong:hover {
									background-color: #181F33;
									color: #fff;
									cursor: default;
								}

								&:last-child, &.kong {
									text-align: center;
									display: block;
									justify-content: start;
									align-items: center;
								}
							}
						}
					}

					i {
						margin-right: 5px;
						font-size: 16px;
					}
				}
			}

			span {
				display: inline-block;
			}

			.user {
				display: flex;
				justify-content: start;
				align-items: center;
				margin-right: auto;
				cursor: pointer;

				p {
					width: 32px;
					height: 32px;
					border-radius: 50%;
					overflow: hidden;
					margin-right: 6px;
				}

				span {
					display: block;
				}

				img {
					width: 100%;
					height: 100%;
					display: block;
				}
			}
		}

		::v-deep .el-switch__core {
			width: 76px !important;
			height: 33px;
			border-radius: 50px;
		}

		::v-deep .el-switch__core:after {
			width: 33px;
			height: 33px;
			top: -1px;
			left: -2px;
		}

		::v-deep.night .el-switch__core:after {
			background: #191932 url(../assets/img/switch_night.png) no-repeat center center;
		}

		::v-deep.day .el-switch__core:after {
			background: #fff url(../assets/img/switch_day.png) no-repeat center center;
		}

		.loginTan {
			width: 288px;
			padding: 6px 23px;
			background-color: #181F33;
			border-radius: 8px;
			position: absolute;
			top: 55px;
			right: 0;

			div {
				width: 100%;
				padding: 0 15px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				line-height: 50px;
				border-radius: 6px;
				transition: background-color 0.3s;
				-webkit-transition: background-color 0.3s;
				font-size: 16px;
				border-bottom: 1px solid #38414F;

				&:last-child {
					border-bottom: 0;
				}

				&:hover {
					background-color: #131622;
				}

				i {
					font-size: 18px;
				}

				.icon {
					margin-right: 10px;
				}

				.ri-arrow-right-s-line {
					margin-left: auto;
					font-size: 18px;
				}
			}
		}
	}

	/* .ceng{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 60px;
		left: 0;
		background-color: rgba(0, 0, 0, 0.7);
	}
	.qrcode {
		width: 80%;
		max-width: 855px;
		border-radius: 6px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 70px 55px 70px 55px;
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 3;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		background-color: #181F33;
		
		.form {
			width: calc(100% - 180px - 60px);
			padding-left: 60px;
			margin-left: 60px;
			border-left: 1px solid #38414F;
			
			h3{
				font-size: 24px;
				font-weight: normal;
				margin-bottom: 20px;
			}
			::v-deep .el-input__inner {
				height: 53px;
				line-height: 53px;
				font-size: 16px;
			}
			
			.submitBtn {
				width: 100%;
				height: 57px;
				font-size: 16px;
				background-color: #3945B4;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&.on{
					background-color: #9295af !important;
					&:hover {
						background-color: #9295af !important;
					}
				}
				&:hover {
					background-color: #4350D8 !important;
				}
				i {
					font-size: 16px;
				}
			}
		}
		
		p{
			font-size: 16px;
			color: #38414F;
			text-align: center;
			margin-top: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			
			a{
				color: #38414F;
				transition: color .2s;
				-webkit-transition: color .2s;
				&:hover {
					color: #fff;
				}
			}
			b{margin: 0 15px;font-weight: normal;}
		}
		
		.close {
			width: 36px;
			height: 36px;
			position: absolute;
			top: 0;
			right: 10px;
			transition: color .2s;
			-webkit-transition: color .2s;
			color: #AAA;
			cursor: pointer;
		
			&:hover {
				color: #fff;
			}
		
			i {
				font-size: 36px;
			}
		}
	} */

	@media (max-width: 1023px) {
		.header {
			.login {
				display: none;
			}
		}
	}

	@media only screen and (min-width: 1366px) and (max-width: 1919px){
		.header .login .upload a{margin-right: 40px;}
	}
	
	@media only screen and (min-width: 1024px) and (max-width: 1365px){
		.header .login .upload a{margin-right: 20px;font-size: 14px;}
		.header .link a{margin: 0 25px;}
	}
	//768--1024
	@media only screen and (min-width: 769px) and (max-width: 1023px) {
		.header {
			.logo {
				.logo_pc {
					display: none;
				}

				.logo_phone {
					display: block;
				}
			}

			.login {
				span {
					display: none;
				}

				.user {
					span {
						display: none;
					}
				}

				.upload {
					a {
						margin-right: 30px;

						i {
							font-size: 20px;
						}
					}
				}

				.share {
					.shareBtn {
						i {
							font-size: 20px;
						}
					}
				}
			}
		
			.link {
				a{margin: 0 25px;}
			}
		}

		.qrcode {
			padding: 40px 35px 50px 35px;

			.form {
				width: calc(100% - 180px - 30px);
				padding-left: 30px;
				margin-left: 30px;
			}
		}
	}

	//ipad
	@media only screen and (min-width: 415px) and (max-width: 768px) {
		.header {
			padding: 0 12px 0 12px;

			.logo {
				.logo_pc {
					display: none;
				}

				.logo_phone {
					display: block;
				}
			}

			.login {
				span {
					display: none;
				}

				.user {
					span {
						display: none;
					}
				}

				.upload {
					a {
						margin-right: 20px;

						i {
							font-size: 18px;
						}
					}
				}

				.share {
					.shareBtn {
						i {
							font-size: 18px;
						}
					}
				}
			}

			.link {
				a {
					margin: 0 15px;
				}
			}
		}

		.qrcode {
			display: block;

			iframe {
				margin: 0 auto;
			}

			padding: 30px 20px 20px 20px;

			.form {
				width: 100%;
				padding-left: 0;
				margin-left: 0;
				border-left: 0;
				padding-top: 15px;
				border-top: 1px solid #38414F;

				::v-deep .el-input__inner {
					height: 42px;
					line-height: 42px;
					font-size: 14px;
				}

				.submitBtn {
					height: 42px;
					font-size: 14px;
				}

				h3 {
					font-size: 16px;
					margin-bottom: 15px;
				}
			}

			p {
				font-size: 12px;
			}

			.close {
				width: 20px;
				height: 20px;

				i {
					font-size: 28px;
				}
			}
		}
	}

	//手机
	@media (max-width: 414px) {
		.header {
			height: 45px;
			padding: 0 12px 0 12px;

			.logo {
				.logo_pc {
					display: none;
				}

				.logo_phone {
					display: block;
				}

				p {
					display: none;
				}
			}

			.login {
				span {
					display: none;
				}

				.user {
					span {
						display: none;
					}
				}

				.upload {
					a {
						margin-right: 20px;

						i {
							font-size: 18px;
						}
					}
				}

				.share {
					.shareBtn {
						i {
							font-size: 18px;
						}
					}
				}

				.loginTan {
					width: 200px;
					padding: 6px 15px;
					top: 50px;

					div {
						line-height: 40px;
						font-size: 14px;

						i {
							font-size: 16px;
						}
					}
				}
			}

			.link {
				a {
					font-size: 14px;
					margin: 0 10px;
				}
			}
		}

		.qrcode {
			display: block;

			iframe {
				margin: 0 auto;
			}

			padding: 30px 20px 20px 20px;

			.form {
				width: 100%;
				padding-left: 0;
				margin-left: 0;
				border-left: 0;
				padding-top: 15px;
				border-top: 1px solid #38414F;

				::v-deep .el-input__inner {
					height: 42px;
					line-height: 42px;
					font-size: 14px;
				}

				.submitBtn {
					height: 42px;
					font-size: 14px;
				}

				h3 {
					font-size: 16px;
					margin-bottom: 15px;
				}
			}

			p {
				font-size: 12px;
			}

			.close {
				width: 20px;
				height: 20px;

				i {
					font-size: 28px;
				}
			}
		}
	}
</style>