import $axios from 'axios';
//let APIURL = 'http://54.183.167.167'
//let APIURL = 'http://aiarthub-api.bedigital.cn'

let header = {
	headers: {
		"Access-Control-Allow-Origin": "*", //解决cors头问题
		"Access-Control-Allow-Credentials": "true", //解决session问题
		"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8", //将表单数据传递转化为form-data类型
		'Authorization': "Bearer",
	},
	//withCredentials: true,   //此处不使用，采用nginx 时，post接收不了数据
};

let network = {
	/* AxiosProduct:(kewords,cid,bid,p)=>{
	  return $axios.get(APIURL+"/API/Product/productlist?searchkey="+kewords+'&cid='+cid+'&bid='+bid+'&p='+p)
	}, */
	
	//home
	AxiosHome: () => {
		return $axios.post('api/element/nav');
	},
	//List
	AxiosList: (params) => {
		return $axios.post('api/element/list',params);
	},
	//搜索
	AxiosSearch: (params) => {
		return $axios.post('api/element/search',params);
	},
	//click 获取常用
	AxiosClick: (ids) => {
		return $axios.post('api/element/click',{eid:ids});
	},
	//获取我的元素(自己上传的) -- 提示器
	AxiosMyElem: (token) => {
		return $axios.post('api/user/airs_list',{_token:token});
	},
	//收藏元素 -- 提示器
	AxiosShouC: (params) => {
		return $axios.post('api/user/airs_collect',params);
	},
	//收藏列表 -- 提示器
	AxiosScList: (token) => {
		return $axios.post('api/user/airs_mycollect',{_token:token});
	},
	//翻译
	AxiosFanyi: (params) => {
		return $axios.post('apiFY/api',params);
	},
	//上传Prompts
	AxiosUpProm: (params) => {
		return $axios.post('api/user/airs_upload',params);
	},
	//发送邮件 -- 注册
	AxiosEmailSend: (params) => {
		return $axios.post('api/login/mail_send',params);
	},
	//邮件注册账号
	AxiosEmailRegister: (params) => {
		return $axios.post('api/login/mail_register',params);
	},
	//发送邮件 -- 忘记密码
	AxiosForgetSend: (params) => {
		return $axios.post('api/login/mail_forget_send',params);
	},
	//修改密码 -- 忘记密码
	AxiosForgetReset: (params) => {
		return $axios.post('api/login/mail_forget_reset',params);
	},
	
	
	
	//微信登录
	AxiosLogin: (code) => {
		return $axios.post('api/login/callback',{code:code});
	},
	
	//邮箱登录
	AxiosMailLogin: (email, pass) => {
		return $axios.post('api/login/mail_login',{email:email,pass:pass});
	},
	
	//退出登录
	AxiosOutLogin: () => {
		return $axios.post('api/user/out',{_token:localStorage.getItem('token_access')});
	},
	
	//广场
	AxiosGuang: (hot, news, p, eid) => {
		return $axios.post('api/work/square',{new:news,hot:hot,p:p,eid:eid,_token:localStorage.getItem('token_access')},header);
	},
	
	//用户作品
	AxiosUser: (hot, news,p, _token) => {
		return $axios.post('api/user/index',{new:news,hot:hot,p:p,_token:localStorage.getItem('token_access')},header)
	},
	
	//上传
	AxiosUpload: (e) => {
		return $axios.post('api/user/upload',e);
	},
	//修改
	AxiosUpdate: (e) => {
		return $axios.post('api/user/update',e,header);
	},
	
	//删除作品
	AxiosDelete: (eid) => {
		return $axios.post('api/user/delete',
			{eid:eid,_token:localStorage.getItem('token_access'),header}
		);
	},
	
	//我的收藏
	AxiosMycollect: (p, size) => {
		return $axios.post('api/user/mycollect',
			{p:p, size:size,_token:localStorage.getItem('token_access'), header}
		);
	},
	
	//广场-作品-评论列表
	AxiosMessageList: (eid) => {
		return $axios.post('api/work/message_list',{eid:eid,_token:localStorage.getItem('token_access')},header);
	},
	//广场-作品-新增评论/回复
	AxiosMessageReply: (eid,reply,mid) => {
		return $axios.post('api/user/message_reply',{eid:eid,reply:reply,mid:mid,_token:localStorage.getItem('token_access')},header);
	},
	
	//广场-作品-点赞
	AxiosMessageAgree: (eid,mid) => {
		return $axios.post('api/user/message_agree',{eid:eid,mid:mid,_token:localStorage.getItem('token_access')},header);
	},
	//广场-作品-删除评论
	AxiosMessageDelete: (eid,mid) => {
		return $axios.post('api/user/message_delete',{eid:eid,mid:mid,_token:localStorage.getItem('token_access')},header);
	},
	
	//关注 参数 openid
	AxiosGuan: (openid) => {
		return $axios.post('api/user/follower',
			{openid:openid,_token:localStorage.getItem('token_access')},header);
	},
	//收藏 参数 eid
	AxiosHeart: (eid) => {
		return $axios.post('api/user/collect',
		{eid:eid,_token:localStorage.getItem('token_access')},header);
	},
	//点赞 参数 eid
	AxiosZan: (eid) => {
		return $axios.post('api/user/agree',
		{eid:eid,_token:localStorage.getItem('token_access')},header);
	},
	
	//我的消息列表
	AxiosMailList: (size) => {
		return $axios.post('api/user/mail_list',
		{size:size,_token:localStorage.getItem('token_access')},header);
	},
	//我的-消息已读/一键已读
	AxiosMailread: (rid, all) => {
		return $axios.post('api/user/mail_read',
		{rid:rid, all:all, _token:localStorage.getItem('token_access')},header);
	},
	
	//微信分享
	AxiosShareWx: (url) => {
		return $axios.post('WXshare', {
			wxmpname : "booe_becky",wxurl : url
		}, {
			timeout: 1000,
			headers: {
				"Access-Control-Allow-Origin": "*", //解决cors头问题
				"Access-Control-Allow-Credentials": "true", //解决session问题
				"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8", //将表单数据传递转化为form-data类型
				'Authorization': "Bearer",
			}
		});
	},
	
	
}

export default network