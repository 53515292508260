<template>
	<div id="app">
		<CompHeader /><!--  :path="path" :userData="userData" -->

		<router-view />

		<!-- 回到顶部 -->
		<el-backtop target="#app"><i class="ri-arrow-up-line"></i></el-backtop>
	</div>
</template>
<script>
	import CompHeader from '@/components/CompHeader.vue' //顶部
	export default {
		name: 'App',
		components: {
			CompHeader,
		},
		data() {
			return {
				//path: this.$route.path,
			}
		},
		created() {
			//console.log(this.$route.query.code)
		},
		mounted() {

		},
		methods: {
			
		},
	}
</script>
<style lang="scss">
	@import '../src/assets/css/base.css';
	@import '../src/assets/css/client.css';

	//@import '../src/assets/css/app.css';
	html,
	body,
	#app {
		height: 100%;
		background-color: #0F0F13;
		overflow-y: auto;
	}

	.el-backtop {
		background-color: #343A68 !important;
		color: #fff !important;
		transition: background-color 0.2s;
		-webkit-transition: background-color 0.2s;

		&:hover {
			background-color: #181F33 !important;
		}
	}

	.el-message {
		background-color: #4350D8 !important;
		border-color: #4350D8 !important;
		color: #fff !important;
		font-size: 16px !important;
		min-width: 145px !important;
		justify-content: center;
		pointer-events: none;

		i {
			color: #fff;
			margin-right: 5px;
			font-size: 18px !important;
		}

		p {
			padding-right: 12px
		}
	}

	.el-message.el-message--warning {
		background-color: #F56C6C !important;
		border-color: #F56C6C !important;
	}

	.el-message .el-icon-warning {
		color: #fff !important;
	}

	.el-message--warning .el-message__content {
		color: #fff !important;
	}

	/* .el-message.el-message--success .el-icon-success{
		color: #fff;
	} */
	/* .el-upload.el-upload--text {
		width: 100%;
		border-radius: 8px;
		.el-upload-dragger {
			width: 100%;
			height: 370px;
			background-color: #121725;
			border: 1px dashed #5C637A;
			border-radius: 8px;
			transition: all .3s;
			-webkit-transition: all .3s;
			&:hover{
				border: 1px dashed #bcbfc3;
			}
		}
		
	} */
	/* 失败弹框 */
	body .el-notification__content {
		margin-top: 4px;
	}

	/* 成功 */
	.el-form-item {
		margin-bottom: 15px !important;
	}

	.el-form .el-form-item__label {
		color: #B7B7B7;
	}

	.el-textarea__inner::placeholder,
	.el-input__inner::placeholder {
		color: #38414F !important;
	}

	.el-form--label-top .el-form-item__label {
		padding: 0 !important;
	}

	.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
	.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		color: #FF0000 !important;
	}

	.el-form .el-textarea__inner {
		background-color: #131622;
		border: 1px solid #38414F;
		border-radius: 6px;
		color: #B7B7B7;
		padding: 9px 15px;
		transition: all .3s;
		-webkit-transition: all .3s;

		&:focus {
			border: 1px solid #bcbfc3;
		}

		&:hover {
			border: 1px solid #bcbfc3;
		}
	}

	.el-form .el-form-item__content {
		position: relative;
	}

	.remove .el-textarea__inner {
		text-indent: 2.5rem;
	}

	.remove-no {
		position: absolute;
		top: 0;
		left: 15px;
		z-index: 5;
		color: #38414F;
	}

	.el-form--label-top .el-form-item__label {
		width: 100% !important;

		.tips {
			float: right;
			color: #657286;
		}
	}

	.el-form .el-input__inner {
		border-color: #38414F;
		background-color: #131622;
		transition: border .3s;
		-webkit-transition: border .3s;
		color: #fff;

		&:hover,
		&:focus {
			border: 1px solid #bcbfc3;
		}

		&:-webkit-autofill {
			box-shadow: 0 0 0px 1000px #131622 inset !important;
			-webkit-text-fill-color: #fff !important;
		}
	}

	input,
	textarea {
		&:-webkit-autofill {
			box-shadow: 0 0 0px 1000px #131622 inset !important;
			-webkit-text-fill-color: #fff !important;
		}
	}

	.el-form .el-button--primary {
		background-color: #3945B4;
		border: 0;
		transition: all .3s;
		-webkit-transition: all .3s;
	}

	.handle {
		position: absolute;
		top: 10px;
		right: 9px;
		z-index: 5;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		opacity: 0;
		transition: opacity 0.5s;
		-webkit-transition: opacity 0.5s;

		.el-button {
			/* width: 32px;
			height: 32px; */
			padding: 8px;
			border: 0;
			background-color: #181F33;
			border-radius: 8px;
			margin-left: 8px;
			color: #fff;
			position: relative;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 13px;
			transition: background-color 0.2s;
			-webkit-transition: background-color 0.2s;

			&:focus {
				background-color: #202944;
			}

			&:hover {
				background-color: #202944;
			}
		}

		.confirmDele {
			width: 60px;
			height: 34px;
			line-height: 34px;
			font-size: 13px;
			position: absolute;
			bottom: -43px;
			right: 0;
			background-color: #e73e3e;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			transition: all 0.2s;
			-webkit-transition: all 0.2s;

			&:hover {
				background-color: #e82323;
			}

			i {
				margin-right: 4px !important;
				font-size: 14px;
			}
		}
	}

	.jia {
		position: absolute;
		top: -5px;
		right: 0;
		opacity: 0;
		margin-top: 0;
		color: #4F5DF5;
		animation: jia 0.4s linear;
		-webkit-animation: jia 0.4s linear;
		pointer-events: none;
		/*点击无效*/
	}

	@keyframes jia {
		0% {
			margin-top: 0;
			opacity: 1;
		}

		40% {
			margin-top: -10;
			opacity: 1;
		}

		100% {
			margin-top: -20px;
			opacity: 0;
		}
	}

	.shareDiv {
		width: auto;
		position: absolute;
		bottom: -60px;
		right: 0;
		background-color: #181f33;
		border-radius: 8px;

		&>div {
			width: 100%;
			text-align: center;
			padding: 5px 10px;
		}
	}

	.loading {
		position: fixed;
		top: 20%;
		left: 50%;
		transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		z-index: 2;
	}

	.social-share .icon-weibo {
		color: #fff;
	}

	.social-share .social-share-icon {
		/* width: 28px;height: 28px; */
		border: 0;
	}

	.social-share .icon-wechat {
		position: relative;
		color: #fff;
		border-color: #fff
	}

	.social-share .icon-wechat:hover {
		background: rgba(43, 162, 69, 1);
		border: 0;
	}

	/* //768--1024 */
	@media only screen and (min-width: 769px) and (max-width: 1023px) {
		.guang {
			.details {
				.container {
					width: 100% !important;
					min-width: 100% !important;
				}
			}
		}
	}

	/* //ipad */
	@media only screen and (min-width: 415px) and (max-width: 768px) {
		.guang {
			padding: 60px 20px 0 20px !important;

			.content {
				padding: 20px 0 !important;
			}

			.details {
				.container {
					width: 100% !important;
					min-width: 100% !important;
					display: block !important;
					max-width: initial;

					.detaL {
						width: 100% !important;
						margin-bottom: 30px !important;
					}

					.detaR {
						width: 100% !important;
					}
				}
			}
		}
	}

	/* //手机 */
	@media (max-width: 414px) {
		.guang {
			padding: 45px 12px 0 12px !important;

			.content {
				padding: 12px 0 !important;
			}

			.details {
				.container {
					width: 100% !important;
					min-width: 100% !important;
					display: block !important;
					max-width: initial !important;

					.detaL {
						width: 100% !important;
						margin-bottom: 30px !important;
					}

					.detaR {
						width: 100% !important;
					}
				}
			}
		}

		.guang .details .container {
			max-width: initial !important;
			justify-content: initial !important;
		}

		.el-backtop {
			width: 40px !important;
			height: 40px !important;
		}
	}
</style>